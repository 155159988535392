<template>
  <div class="order-status-confirm">
    <div class="text-center text-large font-weight-semi-bold mb-1">
      {{ orderLabel }} <span class="font-weight-bold">{{ orderId }}</span>
    </div>
    <div>
      {{ text }}
    </div>
    <div class="d-flex justify-content-center align-items-center text-large mt-1 mb-2">
      <b-badge variant="light-danger">
        {{ oldStatus }}
      </b-badge>

      <feather-icon
        :icon="rtl ? 'ArrowLeftIcon' : 'ArrowRightIcon'"
        class="arrow-icon mx-1"
      />

      <b-badge variant="light-success">
        {{ newStatus }}
      </b-badge>
    </div>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'

export default {
  name: 'OrderStatusChangeConfirm',
  components: {
    BBadge,
  },
  props: {
    orderLabel: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    orderId: {
      type: [Number, String],
      default: null,
    },
    newStatus: {
      type: String,
      default: '',
    },
    oldStatus: {
      type: String,
      default: '',
    },
    rtl: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.order-status-confirm {
  font-size: 14px;

  color: var(--secondary);
}
</style>
